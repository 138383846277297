import dispatchNotesEndpoints from "@/store/endpoints/dispatchNotesEndpoints";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";

export default {

    async associateDispatchNoteWithCustomerOrder(context, dispatchNoteDetails) {
        return await dispatchNotesEndpoints.associateDispatchNoteWithCustomerOrder(dispatchNoteDetails)
    },

    async deleteDispatchNoteCorrelativeAssociation(context, correlativeId) {
        return await dispatchNotesEndpoints.deleteDispatchNoteCorrelativeAssociation(correlativeId);
    },

    async getDispatchNotes(context, pagination) {
        const response = await dispatchNotesEndpoints.getDispatchNotes(pagination)
        if (response.status === 200) context.commit('loadDispatchNotes', response.data)
    },
    async getDispatchNotesByCriteria(context, searchData) {
        const response = await dispatchNotesEndpoints.getDispatchNotesByCriteria(searchData)
        if (response.status === 200) context.commit('loadDispatchNotes', response.data)
    },
    async getDispatchNotesByCriteriaPaged(context, pagination) {
        const response = await dispatchNotesEndpoints.getDispatchNotesByCriteriaPaged(pagination)
        if (response.status === 200) context.commit('loadDispatchNotes', response.data)
    },
    async getDispatchNoteReport(context, printData) {
        const data = {
            params: {
                id: printData.id,
            },
            endpoint: '/dispatch_note_report',
        }
        const response = await reportsEndpoints.getReport(data)
        if (response.status === 200) {
            if (printData.isDownload)
                reportOutputResolver.downloadFile(response)
            else
                reportOutputResolver.openFile(response)
        }
    },
    async getDispatchNotesPendingOfInvoice(context, customerId) {
        const response = await dispatchNotesEndpoints.getDispatchNotesPendingOfInvoice(customerId)
        if (response.status === 200) context.commit('loadDispatchNotes', response.data)
    },
    async getDispatchNotesPendingOfInvoicePaged(context, pagination) {
        const response = await dispatchNotesEndpoints.getDispatchNotesPendingOfInvoicePaged(pagination)
        if (response.status === 200) context.commit('loadDispatchNotes', response.data)
    },
    async getDispatchNoteByNumber(context, dispatchNoteNumber) {
        const response = await dispatchNotesEndpoints.getDispatchNoteByNumber(dispatchNoteNumber)
        if (response.status === 200) context.commit('loadDispatchNote', response.data)
    },
    async getInvoicesByNumber(context, invoiceNumber) {
        const response = await dispatchNotesEndpoints.getInvoicesByNumber(invoiceNumber)
        if (response.status === 200) context.commit('loadInvoices', response.data)
    },
    async getDispatchNotesDeparture(context, pagination) {
        const response = pagination.extraParam
            ? await dispatchNotesEndpoints.getDispatchPendingOfDeparture(pagination)
            : await dispatchNotesEndpoints.getDispatchNotesDeparture(pagination)

        if (response.status === 200) context.commit('loadDispatchNotes', response.data)
    },

    async createOrEditDispatchNote(context, dispatchNote) {
        const response = await dispatchNotesEndpoints.createOrEditDispatchNote(dispatchNote)
        if (response.status === 201) context.commit('addDispatchNote', dispatchNote)
        return response
    },
    async cancelDispatchNote(context, index) {
        const dispatchNote = context.state.dispatchNotes[index] ?? context.state.dispatchNote
        const response = await dispatchNotesEndpoints.cancelDispatchNote(dispatchNote.id)
        if (response.status === 200) dispatchNote.cancelled = true
        return response
    },

    async validateDispatchNote(context, index) {
        const dispatchNote = context.state.dispatchNotes[index]
        const response = await dispatchNotesEndpoints.validateDispatchNote(dispatchNote.id)
        if (response.status === 200) dispatchNote.cancelled = false
        return response
    },

    async cancelDispatchNoteDetail(context, index) {
        const dispatchNoteDetail = context.state.dispatchNotes[index]
        const response = await dispatchNotesEndpoints.cancelDispatchNoteDetail(dispatchNoteDetail.id)
        if (response.status === 200) context.commit('removeDispatchNote', index)
    },
    async revertCancelledDispatchNoteDetail(context, id) {
        return await dispatchNotesEndpoints.revertCancelledDispatchNoteDetail(id)
    },

    async associateDispatchNoteCorrelative(context, assoc) {
        return await dispatchNotesEndpoints.associateDispatchNoteCorrelative(assoc)
    },

    async assocDispatchInvoice(context, couple) {
        const response = await dispatchNotesEndpoints.assocDispatchInvoice(couple)
    },
    async disassociateDispatchNoteInvoice(context, couple) {
        return await dispatchNotesEndpoints.disassociateDispatchNoteInvoice(couple)
    },

    async updateDispatchNoteDelivered(context, dispatchNoteData) {
        return await dispatchNotesEndpoints.updateDispatchNoteDelivered(dispatchNoteData)
    },
}