export default {
  loadFamilies(state, families) {
    state.families = families
  },
  addFamily(state, family) {
    state.families.unshift(family)
  },
  updateFamily(state, data) {
    Object.assign(state.families[data.index], data.item)
  },
  removeFamily(state, index) {
    state.families.splice(index, 1)
  }
}