import axios from "axios";
import {host} from "@/store/config";
import {PDF} from "@/types/printFormats";

export default {
  async getPurchaseOrders(pagination) {
    return await axios.get(host + '/purchase_order', {
      params: {
        limit: pagination.limit,
        page: pagination.page
      }
    })
  },
  async getPurchaseOrderReport(id) {
    return await axios.get(host + '/purchase_order_report', {
      responseType: 'arraybuffer',
      params: {
        id: id,
        extension: PDF.value,
      },
    })
  },
  async getPurchaseOrderByNumber(number) {
    return await axios.get(host + '/purchase_order_search_by_number', {
      params: {
        orderNumber: number,
      },
    })
  },
  async getPurchaseOrderBySupplier(id) {
    return await axios.get(host + '/purchase_order_search_by_supplier', {
      params: {
        id,
      },
    })
  },
  async getProductPurchases(productId) {
    return await axios.get(host + '/historical_purchases_by_product', {
      params: {
        productId: productId,
      },
    })
  },
  async getInternalRequestsDetails(pagination) {
    return await axios.get(host + '/internal_purchase_request_detail', {
      params: {
        limit: pagination.limit,
        page: pagination.page,
      }
    })
  },

  async createOrEditPurchaseOrder(order) {
    return await axios.put(host + '/purchase_order', order)
  },
  async deletePurchaseOrder(id) {
    return await axios.delete(host + '/purchase_order', {
      params: {
        id: id,
      },
    })
  },
  async createOrEditInternalPurchaseRequest(internal) {
    return await axios.put(host + '/internal_purchase_request', internal)
  },
  async deleteInternalRequest(id) {
    return await axios.delete(host + '/internal_purchase_request_detail', {
      params: {
        id: id,
      },
    })
  },
  async updateInternalPurchaseOrderDetail(detail) {
    return await axios.post(host + '/internal_purchase_request_detail', detail)
  }
}