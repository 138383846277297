import familiesEndpoints from "@/store/endpoints/familiesEndpoints";

export default {
  async getFamilies(context) {
    const response = await familiesEndpoints.getFamilies()
    if (response.status === 200) {
      context.commit('loadFamilies', response.data)
    }
  },
  async createFamily(context, data) {
    const response = await familiesEndpoints.createOrEditFamily(data)
    if (response.status === 201) {
      context.commit('addFamily', data)
    }
    return response
  },
  async editFamily(context, data) {
    const response = await familiesEndpoints.createOrEditFamily(data.item)
    if (response.status === 201) {
      context.commit('updateFamily', data)
    }
    return response
  },
  async deleteFamily(context, index) {
    let item = context.state.families[index]
    const response = await familiesEndpoints.deleteFamily(item.id)
    if (response.status === 200) {
      context.commit('removeFamily', index)
    }
  }
}