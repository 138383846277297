export default {
  getSuppliers(state) {
    return state.suppliers
  },
  getFilteredSuppliers(state) {
    return (searchStr, type) => {
      if (type === 'socialReason') {
        return state.suppliers.filter((item) => 
          item.socialReason.toLowerCase().includes(searchStr.toLowerCase())
        )
      }
      if (type === 'documentNumber') {
        return state.suppliers.filter((item) => {
            if (item.documentNumber)
              return item.documentNumber.toLowerCase().includes(searchStr.toLowerCase())
          }
        )
      }
    }
  },
  getThirdPartyChecks(state) {
    return state.thirdPartyChecks
  },
  getCostCenterImputations(state) {
    return state.costCenterImputations
  },
  getSupplierCurrentAccountDetails(state) {
    return state.supplierCurrentAccountDetails
  },
  getSupplierProofsForImputation(state) {
    return state.supplierProofsForImputation
  },
  getSupplierProofsForCompensation(state) {
    return state.supplierProofsForCompensation
  },
  getFilteredSupplierProofsForImputation(state) {
    return search => state.supplierProofsForImputation.filter(
      proof => proof.currentAccountDetail.description.replace('É', 'E').toLowerCase().includes(search.toLowerCase())
    )
  },
  getPaymentsAndCreditNotes(state) {
    return state.paymentsAndCreditNotes
  },
  getImputations(state) {
    return state.imputations
  },
  getPaymentsSchedule(state) {
    return state.paymentsSchedule
  },
  getOwnChecksSchedule(state) {
    return state.ownChecksSchedule
  },
}