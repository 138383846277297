import attachmentEndpoints from "@/store/endpoints/attachmentEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import suppliersEndpoints from "@/store/endpoints/suppliersEndpoints";

export default {
    async getFile(context, data) {
        const response = await attachmentEndpoints.getFile(data)
        if (response.status === 200) {
            reportOutputResolver.openFile(response)
        }
    },
    async deleteFile(context, data) {
        return await attachmentEndpoints.deleteFile(data)
    },
}