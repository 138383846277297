import {uuid} from "vue-uuid";

export function dispatchNoteDetail() {
    return {
        id: uuid.v4(),
        code: '',
        description: '',
        quantity: '',
        unit: 'U',
        product: '',
        customerOrderDetail: '',
        invoiceDetail: '',
        invoiceCancelled: false,
        orderNumber: '',
    }
}