export default {
    loadDispatchNotes(state, dispatchNotes) {
        state.dispatchNotes = dispatchNotes
    },
    loadDispatchNote(state, dispatchNote) {
        state.dispatchNote = dispatchNote
    },
    loadInvoices(state, invoices) {
        state.invoices = invoices
    },

    addDispatchNote(state, dispatchNote) {
        state.dispatchNotes.unshift(dispatchNote)
    },
    removeDispatchNote(state, index) {
        state.dispatchNotes.splice(index, 1)
    },
}