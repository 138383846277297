export default {
    getWorkOrders(state) {
        return state.workOrders
    },
    getWorkOrdersByCode(state) {
        return state.workOrdersByCode
    },
    getPendingProductionOrdersByProduct(state) {
        return state.pendingProductionOrdersByProduct
    },
    getWorkOrdersByNumber(state) {
        return number => state.workOrders.filter(order => order.number.toString().includes(number))
    },
    getWorkOrdersByCustomer(state) {
        return customer => state.workOrders.filter(order =>
            order.customer.socialReason.toLowerCase().includes(customer.toLowerCase()))
    },
    getRelatedOrders(state) {
        return state.relatedOrders
    },
    getProgrammedOrders(state) {
        return state.programmedOrders
    },
    getProgrammedOrdersByOT(state) {
        return search => state.programmedOrders.filter(order =>
            order.number.toString().toLowerCase().includes(search)
        )
    },
    getEmittedOrders(state) {
        return state.emittedOrders
    },
    getEmittedOrdersByOT(state) {
        return search => state.emittedOrders.filter(order =>
            order.number.toString().toLowerCase().includes(search)
        )
    },
    getBarPlateCalculations(state) {
       return state.barPlateCalculations
    },
    getTubePanelCalculations(state) {
        return state.tubePanelCalculations
    },
    getIntercoolerCalculations(state) {
        return state.intercoolerCalculations
    },
    getProductionTubePanels(state) {
        return state.productionTubePanels
    },
    getProductionTubePanelsByOT(state) {
        return search => state.productionTubePanels.filter(panel => panel.order.number.toString().includes(search))
    },
    getProductionIntercoolers(state) {
        return state.productionIntercoolers
    },
    getProductionIntercoolersByOT(state) {
        return search => state.productionIntercoolers.filter(panel => panel.order.number.toString().includes(search))
    },
    getProductionBarPlates(state) {
        return state.productionBarPlates
    },
    getProductionBarPlatesByOT(state) {
        return search => state.productionBarPlates.filter(panel => panel.order.number.toString().includes(search))
    },
    getOperationsByCode(state) {
        return state.operationsByCode
    },
}