import axios from "axios"

export default {
  loadUser(state, data) {
    state.user = data.data.user
    state.role = data.serverData.role
    state.sector = data.serverData.sector
    state.avatarSrc = 'images/' + data.serverData.avatarSrc
    localStorage.setItem('user', data.data.user)
    localStorage.setItem('role', data.serverData.role)
    localStorage.setItem('sector', data.serverData.sector)
    localStorage.setItem('avatarSrc', 'images/' + data.serverData.avatar)
  },
  loadAuth(state, data) {
    state.status = 'success'
    state.authenticated = true
    state.token = data.serverData.token
    localStorage.setItem('authenticated', 'true')
    localStorage.setItem('user-token', data.serverData.token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.serverData.token}`
  },
  logout(state) {
    state.status = ''
    state.authenticated = false
    localStorage.setItem('authenticated', 'false')
    localStorage.removeItem('user-token')
    localStorage.removeItem('avatarSrc')
  },

  loadParameters(state, parameters) {
    state.appParameters = parameters
  },
  uploadParameters(state, parameters) {
    state.appParameters = parameters
  },

  loadSectorsByUser(state, sectors) {
    state.sectorsByUser = sectors
  },

  setFromSectorIds(state, ids) {
    state.fromSectorIds = ids
    localStorage.setItem('fromSectorIds', ids)
  },
  setToSectorIds(state, ids) {
    state.toSectorIds = ids
    localStorage.setItem('toSectorIds', ids)
  }
}