export default {
    drawer: true,
    status: '', // Status de las request
    isLoading: false,
    token: localStorage.getItem('user-token'),
    authenticated: ('true'===localStorage.getItem("authenticated")),
    user: localStorage.getItem("user"),
    sector: localStorage.getItem("sector"),
    avatarSrc: localStorage.getItem("avatarSrc"),
    role: localStorage.getItem("role"),
    fromSectorIds: localStorage.getItem("fromSectorIds"),
    toSectorIds: localStorage.getItem("toSectorIds"),
    favsLinksOnState: [],
    appParameters: [],
    smallScreenHeight: 800,
    sectorsByUser: []
}