export default {
  loadWorkOrders(state, orders) {
    state.workOrders = orders
  },
  loadWorkOrdersByCode(state, orders) {
    state.workOrdersByCode = orders
  },
  loadPendingProductionOrdersByProduct(state, orders) {
    state.pendingProductionOrdersByProduct = orders
  },
  loadRelatedOrders(state, orders) {
    state.relatedOrders = orders
  },
  loadProgrammedOrders(state, orders) {
    state.programmedOrders = orders
  },
  loadEmittedOrders(state, orders) {
    state.emittedOrders = orders
  },
  loadBarPlateCalculations(state, calculations) {
    state.barPlateCalculations = calculations
  },
  loadTubePanelCalculations(state, calculations) {
    state.tubePanelCalculations = calculations
  },
  loadIntercoolerCalculations(state, calculations) {
    state.intercoolerCalculations = calculations
  },
  loadProductionTubePanels(state, panels) {
    state.productionTubePanels = panels
  },
  loadProductionIntercoolers(state, panels) {
    state.productionIntercoolers = panels
  },
  loadProductionBarPlates(state, panels) {
    state.productionBarPlates = panels
  },
  loadOperationsByCode(state, operations) {
    state.operationsByCode = operations
  },

  updateProgrammedOrder(state, data) {
    Object.assign(state.programmedOrders[data.index], data.editingOrder)
  },
  updateEmittedOrder(state, data) {
    Object.assign(state.emittedOrders[data.index], data.editingOrder)
  },

  removeProductionOrder(state, index) {
    state.emittedOrders.splice(index, 1)
  },
}