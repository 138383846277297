import purchasingEndpoints from "@/store/endpoints/purchasingEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";

export default {
  async getPurchaseOrders(context, pagination) {
    const response = await purchasingEndpoints.getPurchaseOrders(pagination)
    if (response.status === 200) context.commit('loadPurchaseOrders', response.data)
  },
  async getProductPurchases(context, productId) {
    const response = await purchasingEndpoints.getProductPurchases(productId)
    if (response.status === 200) context.commit('loadProductPurchases', response.data)
  },
  async getPurchaseOrderByNumber(context, number) {
    const response = await purchasingEndpoints.getPurchaseOrderByNumber(number)
    if (response.status === 200) context.commit('loadPurchaseOrderByNumber', response.data)
  },
  async getPurchaseOrderBySupplier(context, id) {
    const response = await purchasingEndpoints.getPurchaseOrderBySupplier(id)
    if (response.status === 200) context.commit('loadPurchaseOrders', response.data)
  },
  async getInternalRequestsDetails(context, pagination) {
    const response = await purchasingEndpoints.getInternalRequestsDetails(pagination)
    if (response.status === 200) context.commit('loadInternalRequestsDetails', response.data)
  },
  async getPurchaseOrderReport(context, id) {
    const response = await purchasingEndpoints.getPurchaseOrderReport(id)
    if (response.status === 200) reportOutputResolver.openFile(response)
  },

  async createOrEditPurchaseOrder(context, order) {
    return await purchasingEndpoints.createOrEditPurchaseOrder(order)
  },
  async deletePurchaseOrder(context, index) {
    const purchOrder = context.state.purchaseOrders[index]
    const response = await purchasingEndpoints.deletePurchaseOrder(purchOrder.id)
    if (response.status === 200) context.commit('removePurchaseOrder', index)
  },
  async createOrEditInternalPurchaseRequest(context, internal) {
    return await purchasingEndpoints.createOrEditInternalPurchaseRequest(internal)
  },
  async deleteInternalRequest(context, index) {
    const internalId = context.state.internalPurchaseRequests[index].id
    const response = await purchasingEndpoints.deleteInternalRequest(internalId)
    if (response.status === 200) context.commit('removeInternalRequest', index)
  },
  async updateInternalPurchaseOrderDetail(context, detail) {
    await purchasingEndpoints.updateInternalPurchaseOrderDetail(detail)
  },
}