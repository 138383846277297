import subfamiliesEndpoints from "@/store/endpoints/subfamiliesEndpoints";

export default {
  async getSubfamilies(context) {
    const response = await subfamiliesEndpoints.getSubfamilies()
    if (response.status === 200) {
      context.commit('loadSubfamilies', response.data)
    }
  },
  async createSubfamily(context, data) {
    const response = await subfamiliesEndpoints.createOrEditSubFamily(data)
    if (response.status === 201) {
      context.commit('addSubfamily', data)
    }
    return response
  },
  async editSubfamily(context, data) {
    const response = await subfamiliesEndpoints.createOrEditSubFamily(data.item)
    if (response.status === 201) {
      context.commit('updateSubfamily', data)
    }
    return response
  },
  async deleteSubfamily(context, index) {
    let item = context.state.subfamilies[index]
    const response = await subfamiliesEndpoints.deleteSubfamily(item.id)
    if (response.status === 200) {
      context.commit('removeSubfamily', index)
    }
  },
}