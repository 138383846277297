import axios from "axios";
import {host} from "@/store/config";

export default {
  async getCorrelatives(pagination) {
    return await axios.get(host + '/correlative', {
      params: {
        limit: pagination.limit,
        page: pagination.page,
      }
    })
  },
  async getLastCorrelativeNumber() {
    return await axios.get(host + '/correlative_last')
  },
  async getCorrelativeByNumber(number) {
    return await axios.get(host + '/correlative_search', {
      params: {
        number: number,
      }
    })
  },
  async getPressureTests(pagination) {
    return await axios.get(host + '/pressure_test', {
      params: {
        limit: pagination.limit,
        page: pagination.page,
      }
    })
  },
  async getPressureTestByNumber(number) {
    return await axios.get(host + '/pressure_test_search', {
      params: {
        testNumber: number,
      }
    })
  },
  async getPressureTestByOT(number) {
    return await axios.get(host + '/pressure_test_search', {
      params: {
        orderNumber: number,
      }
    })
  },
  async getPressureTestLastNumber() {
    return await axios.get(host + '/pressure_test_last_number')
  },
  async getReceptionControlProducts(pagination) {
    return await axios.get(host + '/quality_control_product_reception_searcher', {
      params: {
        limit: pagination.limit,
        page: pagination.page,
      }
    })
  },
  async getReceptionControlByProduct(productId) {
    return await axios.get(host + '/quality_control_product_reception_searcher_by_product', {
      params: {
        productId: productId,
      }
    })
  },

  async createCorrelative(correlative) {
    return await axios.put(host + '/correlative', correlative)
  },
  async deleteCorrelative(id) {
    return await axios.delete(host + '/correlative', {
      params: {
        id: id,
      }
    })
  },
  async createOrEditPressureTest(test) {
    return await axios.put(host + '/pressure_test', test)
  },
  async deletePressureTest(id) {
    return await axios.delete(host + '/pressure_test', {
      params: {
        id: id,
      }
    })
  },
  async updateQualityProductReception(data) {
    let formData = new FormData()
    data.attachment.forEach(file => formData.append('files[]', file, file.fileName))
    formData.append('data', JSON.stringify(data.quality))

    return await axios.post(host + '/quality_control_product_reception_updater', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}