export default {
  loadExchangeParameters(state, exchanges) {
    state.exchangeRateParameters = exchanges
  },
  loadPricesLists(state, pricesLists) {
    state.pricesLists = pricesLists
  },
  loadProductsInList(state, details) {
    state.pricesListDetails = details
  },
  loadBanks(state, banks) {
    state.banks = banks
  },
  loadBankAccounts(state, bankAccounts) {
    state.bankAccounts = bankAccounts
  },
  loadBankConcepts(state, concepts) {
    state.bankConcepts = concepts
  },
  loadBankMovements(state, bankMovements) {
    state.bankMovements = bankMovements
  },
  loadPaymentMethods(state, paymentMethods) {
    state.paymentMethods = paymentMethods
  },
  loadBankCards(state, cards) {
    state.bankCards = cards
  },
  loadWallets(state, wallets) {
    state.wallets = wallets
  },

  addExchangeRateParameter(state, exchange) {
    state.exchangeRateParameters.unshift(exchange)
  },
  removeExchangeRateParameter(state, index) {
    state.exchangeRateParameters.splice(index, 1)
  },

  addPriceList(state, list) {
    state.pricesLists.unshift(list)
  },
  removePriceList(state, index) {
    state.pricesLists.splice(index, 1)
  },

  addBank(state, bank) {
    state.banks.unshift(bank)
  },
  uploadBank(state, data) {
    Object.assign(state.banks[data.index], data.bank)
  },
  removeBank(state, index) {
    state.banks.splice(index, 1)
  },

  addBankAccount(state, bankAccount) {
    state.bankAccounts.unshift(bankAccount)
  },
  uploadBankAccount(state, data) {
     Object.assign(state.bankAccounts[data.index], data.bankAccount)
  },
  removeBankAccount(state, index) {
    state.bankAccounts.splice(index, 1)
  },

  addBankConcept(state, concept) {
    state.bankConcepts.unshift(concept)
  },
  uploadBankConcept(state, data) {
    Object.assign(state.bankConcepts[data.index], data.bankConcept)
  },
  removeBankConcept(state, index) {
    state.bankConcepts.splice(index, 1)
  },

  addBankMovement(state, movement) {
    state.bankMovements.unshift(movement)
  },
  removeBankMovement(state, index) {
    state.bankMovements.splice(index, 1)
  },

  addBankCard(state, card) {
    state.bankCards.unshift(card)
  },

  removeBankCard(state, index) {
    state.bankCards.splice(index, 1)
  },

  addWallet(state, wallet) {
    state.wallets.unshift(wallet)
  },
  removeWallet(state, index) {
    state.wallets.splice(index, 1)
  },
}