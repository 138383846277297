import {host} from "@/store/config";
import axios from "axios";
import {dispatchNoteDetail} from "@/objects/dispatchNote/dispatchNoteDetail";

export default {
    async associateDispatchNoteWithCustomerOrder(dispatchNoteDetails) {
        let dispatchNoteDetail = dispatchNoteDetails[0]
        return await axios.post(host + '/dispatch_note_customer_order_relator', dispatchNoteDetails)
    },

    async deleteDispatchNoteCorrelativeAssociation(correlativeId) {
        return await axios.post(host + '/dispatch_note_detail_correlative_separator', null, {
            params: {
                correlativeId: correlativeId,
            }
        })
    },
    async getDispatchNotes(pagination) {
        return await axios.get(host + '/dispatch_note', {
            params: {
                limit: pagination.limit,
                page: pagination.page,
            }
        })
    },
    async getDispatchNotesByCriteria(searchData) {
        return await axios.get(host + '/dispatch_note_search_by', {
            params: {
                customerId: searchData.customerId,
                supplierId: searchData.supplierId,
                productId: searchData.productId
            }
        })
    },
    async getDispatchNotesByCriteriaPaged(pagination) {
        return await axios.get(host + '/dispatch_note_search_by', {
            params: {
                customerId: pagination.extraParams.customerId,
                supplierId: pagination.extraParams.supplierId,
                productId: pagination.extraParams.productId,
                limit: pagination.limit,
                page: pagination.page
            }
        })
    },
    async getDispatchNotesPendingOfInvoice(customerId) {
        return await axios.get(host + '/dispatch_note_pending_of_invoice', {
            params: {
                customerId: customerId,
            }
        })
    },
    async getDispatchNotesPendingOfInvoicePaged(pagination) {
        return await axios.get(host + '/dispatch_note_pending_of_invoice', {
            params: {
                customerId: pagination.extraParam,
                limit: pagination.limit,
                page: pagination.page,
            }
        })
    },
    async getDispatchNoteByNumber(dispatchNoteNumber) {
        return await axios.get(host + '/dispatch_note_by_number', {
            params: {
                dispatchNoteNumber: dispatchNoteNumber,
            }
        })
    },
    async getInvoicesByNumber(invoiceNumber) {
        return await axios.get(host + '/invoice_by_number', {
            params: {
                invoiceNumber: invoiceNumber,
            }
        })
    },
    async getDispatchPendingOfDeparture(pagination) {
        return await axios.get(host + '/dispatch_note_departure', {
            params: {
                limit: pagination.limit,
                page: pagination.page,
                deliveryPending: true
            }
        })
    },
    async getDispatchNotesDeparture(pagination) {
        return await axios.get(host + '/dispatch_note_departure', {
            params: {
                limit: pagination.limit,
                page: pagination.page,
            }
        })
    },

    async createOrEditDispatchNote(dispatchNote) {
        return await axios.post(host + '/dispatch_note', dispatchNote)
    },
    async cancelDispatchNote(id) {
        return await axios.delete(host + '/dispatch_note', {
            params: {
                id: id,
            }
        })
    },

    async validateDispatchNote(id) {
        return await axios.post(host + '/dispatch_note_validate', null, {
            params: {
                id: id,
            }
        })
    },

    async cancelDispatchNoteDetail(id) {
        return await axios.put(host + '/dispatch_note_canceller', null, {
            params: {
                id: id,
            }
        })
    },
    async revertCancelledDispatchNoteDetail(id) {
        return await axios.put(host + '/dispatch_note_detail_cancelled_revert', null, {
            params: {
                id: id,
            }
        })
    },

    async associateDispatchNoteCorrelative(assoc) {
        return await axios.post(host + '/dispatch_note_detail_correlative_relator', null, {
            params: {
                correlativeNumbers: assoc.correlativeNumbers,
                dispatchNoteNumber: assoc.dispatchNoteNumber
            }
        })
    },
    async assocDispatchInvoice(couple) {
        return await axios.post(host + '/dispatch_note_detail_invoice_relator', null, {
            params: {
                dispatchNoteDetailId: couple.dispatchNoteDetailId,
                invoiceDetailId: couple.invoiceDetailId
            }
        })
    },
    async disassociateDispatchNoteInvoice(couple) {
        return await axios.post(host + '/dispatch_note_detail_invoice_separator', null, {
            params: {
                dispatchNoteDetailId: couple.dispatchNoteDetailId,
                invoiceDetailId: couple.invoiceDetailId
            }
        })
    },

    async updateDispatchNoteDelivered(dispatchNoteData) {
        return await axios.put(host + '/dispatch_note_delivered_update', null, {
            params: {
                dispatchNoteId: dispatchNoteData.dispatchNoteId,
                deliveredDate: dispatchNoteData.deliveredDate,
            }
        })
    },
}

