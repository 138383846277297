export default {
  loadSubfamilies(state, subfamilies) {
    state.subfamilies = subfamilies
  },
  addSubfamily(state, subfamily) {
    state.subfamilies.unshift(subfamily)
  },
  updateSubfamily(state, data) {
    Object.assign(state.subfamilies[data.index], data.item)
  },
  removeSubfamily(state, index) {
    state.subfamilies.splice(index, 1)
  }
}