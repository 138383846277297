export default {
  loadPurchaseOrders(state, orders) {
    state.purchaseOrders = orders
  },
  loadPurchaseOrderByNumber(state, orders) {
    state.purchaseOrderByNumber = orders
  },
  loadProductPurchases(state, details) {
    state.productPurchases = details
  },
  loadInternalRequestsDetails(state, details) {
    state.internalPurchaseRequests = details
  },

  removePurchaseOrder(state, index) {
    state.purchaseOrders.splice(index, 1)
  },
  cleanPurchaseOrderByNumber(state) {
    state.purchaseOrderByNumber = []
  },
  removeInternalRequest(state, index) {
    state.internalPurchaseRequests.splice(index, 1)
  }
}