import axios from 'axios';
import { host } from '@/store/config';

export default {
  async login(data) {
    let formData = new FormData();
    formData.append('username', data.user)
    formData.append('password', data.password)

    return await axios.post(host + '/login', formData)
  },

  async updatePassword(data) {
    let formData = new FormData();
    formData.append('newPassword', data.newPassword)
    formData.append('confirmedPassword', data.confirmedPassword)

    return await axios.post(host + '/update_password', formData)
  },

  async getSectorsByUser(userId) {
    return await axios.get(host + '/sectors_by_user', {
      params: {
        userId: userId,
      }
    })
  }
}