import qualityEndpoints from "@/store/endpoints/qualityEndpoints";

export default {
  async getPressureTests(context, pagination) {
    const response = await qualityEndpoints.getPressureTests(pagination)
    if (response.status === 200) context.commit('loadPressureTests', response.data)
  },
  async getPressureTestByNumber(context, number) {
    const response = await qualityEndpoints.getPressureTestByNumber(number)
    if (response.status === 200) context.commit('loadPressureTests', response.data)
  },
  async getPressureTestByOT(context, number) {
    const response = await qualityEndpoints.getPressureTestByOT(number)
    if (response.status === 200) context.commit('loadPressureTests', response.data)
  },
  async getPressureTestLastNumber(context) {
    const response =  await qualityEndpoints.getPressureTestLastNumber()
    if (response.status === 200) return response.data
  },
  async getCorrelatives(context, pagination) {
    const response = await qualityEndpoints.getCorrelatives(pagination)
    if (response.status === 200) context.commit('loadCorrelatives', response.data)
  },
  async getLastCorrelativeNumber(context) {
    const response = await qualityEndpoints.getLastCorrelativeNumber()
    if (response.status === 200) return response.data
  },
  async getCorrelativeByNumber(context, number) {
    const response = await qualityEndpoints.getCorrelativeByNumber(number)
    if (response.status === 200) context.commit('loadCorrelatives', response.data)
    return response.data
  },
  async getReceptionControlProducts(context, pagination) {
    const response = await qualityEndpoints.getReceptionControlProducts(pagination)
    if (response.status === 200) context.commit('loadReceptionControlProducts', response.data)
  },
  async getReceptionControlByProduct(context, productId) {
    const response = await qualityEndpoints.getReceptionControlByProduct(productId)
    if (response.status === 200) context.commit('loadReceptionControlProducts', response.data)
  },

  async createCorrelative(context, correlative) {
    return await qualityEndpoints.createCorrelative(correlative)
  },
  async deleteCorrelative(context, index) {
    const correlative = context.state.correlatives[index]
    const response = await qualityEndpoints.deleteCorrelative(correlative.id)
    if (response.status === 200) context.commit('removeCorrelative', index)
  },
  async createOrEditPressureTest(context, data) {
    const response = await qualityEndpoints.createOrEditPressureTest(data.pressureTest)
    if (response.status === 201)
      if (data.index > -1)
        context.commit('updatePressureTest', data)
      else
        context.commit('addPressureTest', data.pressureTest)

    return response
  },
  async deletePressureTest(context, index) {
    const test = context.state.pressureTests[index]
    const response = await qualityEndpoints.deletePressureTest(test.id)
    if (response.status === 200) context.commit('removePressureTest', index)
  },

  async updateQualityProductReception(context, data) {
    const response = await qualityEndpoints.updateQualityProductReception(data)
    if (response.status === 201) {
      data.quality.attachmentCollection = response.data
      context.commit('updateQualityProductReception', data)
    }
  },
}