import axios from "axios";
import {host} from "@/store/config";

export default {
  async getCustomers() {
    return await axios.get(host + '/customers')
  },
  async getCustomerData(id) {
    return await axios.get(host + '/customers', {
      params: {
        id: id,
      }
    })
  },
  async getTransports() {
    return await axios.get(host + '/transports')
  },
  async getQuotations() {
    return await axios.get(host + '/quotation')
  },
  async getQuotationsByProduct(productId) {
    return await axios.get(host + '/quotation_search_by_product', {
      params: {
        productId: productId
      }
    })
  },
  async getCustomerCurrentAccountDetails(pagination) {
    return await axios.get(host + '/customer_account_detail', { params: {
      id: pagination.extraParam,
      limit: pagination.limit,
      page: pagination.page
    }})
  },
  async getProofsByCustomer(customerId) {
    return await axios.get(host + '/proofs_by_customer', {
      params: {
        id: customerId
      }
    })
  },
  async getCustomerProofsForImputation(data) {
    return await axios.get(host + '/commercial_customer_proofs_for_imputation', {
      params: {
        invoiceType: data.invoiceType,
        customerId: data.customerId
      }
    })
  },
  async getCollectionProofsForImputation(customerId) {
    return await axios.get(host + '/proofs_for_imputation_on_collection_finder', {
      params: {
        id: customerId,
      }
    })
  },
  async getCollectionProofsForImputationPaged(pagination) {
    return await axios.get(host + '/proofs_for_imputation_on_collection_finder', {
      params: {
        id: pagination.extraParam,
        limit: pagination.limit,
        page: pagination.page,
      }
    })
  },
  async getCustomerProofsForCompensationPaged(pagination) {
    return await axios.get(host + '/customer_proofs_for_compensation', {
      params: {
        customerId: pagination.extraParam,
        limit: pagination.limit,
        page: pagination.page,
      }
    })
  },
  async getCollectionsAndCreditNotes(accountId) {
    return await axios.get(host + '/proofs_to_apply_on_imputations_finder', {
      params: {
        id: accountId
      }
    })
  },
  async getProductSalePrice(data) {
    return await axios.get(host + '/product_sale_price', {
      params: {
        productId: data.productId,
        customerId: data.customerId,
        customerOrderDetailId: data.customerOrderDetailId,
      }
    })
  },
  async getInvoicePerception(customerProof) {
    return await axios.post(host + '/invoice_perception', customerProof)
  },
  async getImputations(pagination) {
    return await axios.get(host + '/imputations_searcher', {
      params: {
        limit: pagination.limit,
        page: pagination.page,
        currentAccountId: pagination.extraParam
      }
    })
  },
  async getImputationsByProofNumber(searchData) {
    return await axios.get(host + '/imputations_searcher', {
      params: {
        currentAccountId: searchData.accountId,
        proofNumber: searchData.proofNumber
      }
    })
  },

  async createOrEditCustomer(data) {
    let formData = new FormData()
    data.attachment.forEach(function (file) {
      formData.append('files[]', file, file.fileName)
    })
    formData.append('data', JSON.stringify(data.item))
    return await axios.post(host + '/customers', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  async deleteContact(id) {
    return await axios.delete(host + '/customer_contact', { params: {
        id: id,
      }
    })
  },
  async deleteCustomerPricesList(id) {
    return await axios.delete(host + '/customer_price_list', { params: {
        id: id,
      }})
  },
  async deleteCurrentAccount(account) {
    return await axios.delete(host + '/current_account', { params: {
        id: account.id,
        type: account.type
      }
    })
  },
  async deleteBankAccount(id) {
    return await axios.delete(host + '/customer_bank_account', { params: {
        id: id,
      }
    })
  },
  async deleteCustomer(id) {
    return await axios.delete(host + '/customers', { params: {
      id: id,
    }})
  },

  async createOrEditTransport(item) {
    return await axios.put(host + '/transport', item)
  },
  async deleteTransport(id) {
    return await axios.delete(host + '/transport', { params: {
      id: id,
    }})
  },

  async getSellers() {
    return await axios.get(host + '/seller')
  },
  async createOrEditSeller(seller) {
    return await axios.put(host + '/seller', seller)
  },
  async deleteSeller(id) {
    return await axios.delete(host + '/seller', {
      params: {
        id
      }
    })
  },

  async createQuotation(quotation) {
    return await axios.put(host + '/quotation', quotation)
  },
  async deleteQuotation(id) {
    return await axios.delete(host+ '/quotation', {
      params: {
        id: id,
      }
    })
  },

  async createCommercialCustomerProof(proof) {
    return await axios.put(host + '/invoice', proof)
  },
  async createCollection(collection) {
    return await axios.put(host + '/collection', collection)
  },
  async createCurrentAccountBalance(balance) {
    return await axios.put(host + '/customer_account_balance_creator', balance)
  },
  async saveCurrentAccountNotes(currentAccount) {
    return await axios.put(host + '/customer_current_account_notes', currentAccount)
  },
  async deleteCustomerCurrentAccountDetail(detailId) {
    return await axios.delete(host + '/customer_current_account_detail', {
      params: {
        detailId,
      }
    })
  },
  async createCustomerImputations(imputations) {
    return await axios.put(host + '/customer_proof_imputation', imputations)
  },
  async deleteCustomerImputation(id) {
    return await axios.delete(host + '/imputed_collection', {
      params: {
        id: id,
      }
    })
  }
}