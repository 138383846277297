export default {
    getDispatchNotes(state) {
        return state.dispatchNotes
    },
    getDispatchNote(state) {
        return state.dispatchNote
    },
    getDispatchNoteDetailsFiltered: (state) => (searchType, searchStr) => {
        if (searchType === 'customer') {
            return state.dispatchNotes.filter(
                detail => detail.dispatchNote?.customer?.socialReason.toLowerCase().includes(searchStr.toLowerCase())
            )
        }
        if (searchType === 'supplier') {
            return state.dispatchNotes.filter(
                detail => detail.dispatchNote?.supplier?.socialReason.toLowerCase().includes(searchStr.toLowerCase())
            )
        }
        if (searchType === 'code') {
            return state.dispatchNotes.filter(
                detail => detail.product.code.toLowerCase().includes(searchStr.toLowerCase())
            )
        }
    },
    getInvoices(state) {
        return state.invoices
    }
}