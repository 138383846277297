import financesEndpoints from "@/store/endpoints/financesEndpoints";
import reportsEndpoints from "@/store/endpoints/reportsEndpoints";
import reportOutputResolver from "@/utils/reportOutputResolver";
import {PDF} from "@/types/printFormats";

export default {
  async getExchangeParameters(context) {
    const response = await financesEndpoints.getExchangeParameters()
    if (response.status === 200) context.commit('loadExchangeParameters', response.data)
  },
  async getPricesLists(context) {
    const response = await financesEndpoints.getPricesLists()
    if (response.status === 200) {
      context.commit('loadPricesLists', response.data)
    }
  },
  async getProductsInList(context) {
    const response = await financesEndpoints.getProductsInList()
    if (response.status === 200) context.commit('loadProductsInList', response.data)
  },
  async getBanks(context) {
    const response = await financesEndpoints.getBanks()
    if (response.status === 200) {
      context.commit('loadBanks', response.data)
    }
  },
  async getBankAccounts(context) {
    const response = await financesEndpoints.getBankAccounts()
    if (response.status === 200) {
      context.commit('loadBankAccounts', response.data)
    }
  },
  async getBankConcepts(context) {
    const response = await financesEndpoints.getBankConcepts()
    if (response.status === 200) {
      context.commit('loadBankConcepts', response.data)
    }
  },
  async getBankMovements(context, pagination) {
    const response = await financesEndpoints.getBankMovements(pagination)
    if (response.status === 200) context.commit('loadBankMovements', response.data)
  },
  async getBankMovementDetailReport(context, movementId) {
    const data = {
      params: {
        format: PDF.value,
        id: movementId,
      },
      endpoint: '/bank_movement_detail_report',
    }

    const response = await reportsEndpoints.getReport(data)
    if (response.status === 200) reportOutputResolver.openFile(response)
  },
  async getBankCards(context) {
    const response = await financesEndpoints.getBankCards()
    if (response.status === 200) context.commit('loadBankCards', response.data)
  },
  async getWallets(context) {
    const response = await financesEndpoints.getWallets()
    if (response.status === 200) context.commit('loadWallets', response.data)
  },
  async getPerceptionTxt(context, perceptionData) {
    const response = await financesEndpoints.getPerceptionTxt(perceptionData)
    if (response.status === 200) reportOutputResolver.downloadFile(response)
  },

  async saveExchangeRateParameter(context, exchange) {
    const response = await financesEndpoints.saveExchangeRateParameter(exchange)
    if (response.status === 201) context.commit('addExchangeRateParameter', exchange)
  },
  async deleteExchangeRateParameter(context, exchange) {
    const index = context.state.exchangeRateParameters.indexOf(exchange)
    const response = await financesEndpoints.deleteExchangeRateParameter(exchange.id)
    if (response.status === 200) context.commit('removeExchangeRateParameter', index)
  },

  async createOrEditPricesList(context, pricesList) {
    const response = await financesEndpoints.createOrEditPricesList(pricesList)
    if (response.status === 201) {
      context.commit('addPriceList', pricesList)
    }
  },
  async deletePricesList(context, index) {
    let list = context.state.pricesLists[index]
    const response = await financesEndpoints.deletePricesList(list.id)
    if (response.status === 200) {
      context.commit('removePriceList', index)
    }
  },
  async createOrEditPriceListDetail(context, detail) {
    return await financesEndpoints.createOrEditPriceListDetail(detail)
  },
  async removeDetailFromList(context, id) {
    return await financesEndpoints.removeDetailFromList(id)
  },

  async createOrEditPaymentType(context, data) {
    const response = await financesEndpoints.createOrEditPaymentType(data.paymentType)
    if (response.status === 200) {
      if (data.index > -1) {
        context.commit('uploadPaymentMethod', data)
      } else {
        context.commit('addPaymentMethod', data.paymentType)
      }
    }
    return response
  },

  async createOrEditBank(context, data) {
    const response = await financesEndpoints.createOrEditBank(data.bank)
    if (response.status === 200) {
      if (data.index > -1) {
        context.commit('uploadBank', data)
      } else {
        context.commit('addBank', data.bank)
      }
    }
    return response
  },
  async deleteBank(context, index) {
    const bank = context.state.banks[index]
    const response = await financesEndpoints.deleteBank(bank.id)
    if (response.status === 200) context.commit('removeBank', index)
  },

  async createBankAccount(context, bankAccount) {
    const response = await financesEndpoints.createOrEditBankAccount(bankAccount)
    if (response.status === 200) context.commit('addBankAccount', bankAccount)
    return response
  },
  async editBankAccount(context, data) {
    const response = await financesEndpoints.createOrEditBankAccount(data.bankAccount)
    if (response.status === 200) context.commit('uploadBankAccount', data)
    return response
  },
  async deleteBankAccount(context, index) {
    const bankAcc = context.state.bankAccounts[index]
    const response = await financesEndpoints.deleteBankAccount(bankAcc.id)
    if (response.status === 200) context.commit('removeBankAccount', index)
  },

  async createOrEditBankConcept(context, data) {
    const response = await financesEndpoints.createOrEditBankConcept(data.bankConcept)
    if (response.status === 201) {
        context.commit('addBankConcept', data.bankConcept)
    }
    return response
  },
  async deleteBankConcept(context, index) {
    const concept = context.state.bankConcepts[index]
    const response = await financesEndpoints.deleteBankConcept(concept.id)
    if (response.status === 200) context.commit('removeBankConcept', index)
  },

  async createOrEditBankMovement(context, bankMovement) {
    const response = await financesEndpoints.createOrEditBankMovement(bankMovement)
    if (response.status === 201) {
      context.commit('addBankMovement', bankMovement)
    }
    return response
  },
  async deleteBankMovement(context, index) {
    const movement = context.state.bankMovements[index]
    const response = await financesEndpoints.deleteBankMovement(movement.id)
    if (response.status === 200) context.commit('removeBankMovement', index)
  },

  async createOrEditCard(context, data) {
    const response = await financesEndpoints.createOrEditCard(data.card)
    if (response.status === 201) context.commit('addBankCard', data.card)

    return response
  },
  async deleteBankCard(context, index) {
    let card = context.state.bankCards[index]
    const response = await financesEndpoints.deleteBankCard(card.id)

    if (response.status === 200) context.commit('removeBankCard', index)
  },

  async createOrEditWallet(context, wallet) {
    const response = await financesEndpoints.createOrEditWallet(wallet)
    if (response.status === 201) context.commit('addWallet', wallet)
    return response
  },
  async deleteWallet(context, index) {
    let wallet = context.state.wallets[index]
    const response = await financesEndpoints.deleteWallet(wallet.id)
    if (response.status === 200) context.commit('removeWallet', index)
  }
}
