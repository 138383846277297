export default {
  getCustomers(state) {
    return state.customers
  },
  getFilteredCustomers: (state) => (searchStr, type) => {
    if (type === 'socialReason') {
      return state.customers.filter((item) => 
        item.socialReason.toLowerCase().includes(searchStr.toLowerCase())
      )
    }
    if (type === 'documentNumber') {
      return state.customers.filter((item) => {
          if (item.documentNumber)
            return item.documentNumber.toLowerCase().includes(searchStr.toLowerCase())
        }
      )
    }
  },
  getTransports(state) {
    return state.transports
  },
  getFilteredTransports(state) {
    return searchStr => {
      return state.transports.filter(item => 
        item.name.toLowerCase().includes(searchStr.toLowerCase()))
    }
  },
  getPricesLists(state) {
    return state.pricesLists
  },
  getCustomerCurrentAccountDetails(state) {
    return state.customerCurrentAccountDetails
  },
  getCustomerProofsForImputation(state) {
    return state.customerProofsForImputation
  },
  getCustomerProofsForCompensation(state) {
    return state.customerProofsForCompensation
  },
  getCollectionProofsForImputation(state) {
    return state.collectionProofsForImputation
  },
  getFilteredCollectionProofsForImputation(state) {
    return search => state.collectionProofsForImputation.filter(
      proof => proof.description.replace('É', 'E').toLowerCase().includes(search.toLowerCase())
    )
  },
  getCollectionsAndCreditNotes(state) {
    return state.collectionsAndCreditNotes
  },
  getImputations(state) {
    return state.imputations
  },
  getSellers(state) {
    return state.sellers
  },
  getQuotations(state) {
    return state.quotations
  },
  getQuotationsByCustomer(state) {
    return searchStr => state.quotations.filter(quotation => quotation.customerName.toLowerCase().includes(searchStr.toLowerCase()))
  },
}