export default {
    workOrders: [],
    workOrdersByCode: [],
    pendingProductionOrdersByProduct: [],
    relatedOrders: [],
    programmedOrders: [],
    emittedOrders: [],
    barPlateCalculations: [],
    tubePanelCalculations: [],
    intercoolerCalculations: [],
    productionTubePanels: [],
    productionIntercoolers: [],
    productionBarPlates: [],
    operationsByCode: [],
}