import {PurchaseOrders, Suppliers} from "@/types/entities";

export default {
  getPurchaseOrders(state) {
    return state.purchaseOrders
  },
  getProductPurchases(state) {
    return state.productPurchases
  },
  getFilteredPurchaseOrders(state) {
    return (searchStr, type) => {
      if (type === Suppliers)
        return state.purchaseOrders.filter(order =>
          order.supplier.socialReason.toLowerCase().includes(searchStr.toLowerCase()))

      if (type === PurchaseOrders)
        return state.purchaseOrders.filter(order =>
          order.orderNumber.includes(searchStr))
    }
  },
  getPurchaseOrderByNumber(state) {
    return state.purchaseOrderByNumber
  },
  getPurchaseOrderDetailsByNumber(state) {
    return state.purchaseOrderByNumber?.orderDetails
  },
  getInternalPurchaseRequests(state) {
    return state.internalPurchaseRequests
  },
  getInternalPurchaseRequestsBySupplier(state) {
    return searchStr => {
      return state.internalPurchaseRequests.filter(
        request => {
          if (request.supplier)
            return request.supplier.socialReason.toLowerCase().includes(searchStr.toLowerCase())
        }
      )
    }
  },
}