import customerOrdersEndpoints from "@/store/endpoints/customerOrdersEndpoints";

export default {
    async getCustomerOrderDetail(context, id) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetail(id)
        return response.data
    },
    async getCustomerOrderDetails(context, pagination) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetails(pagination)
        await loadCustomerOrderDetails(response, context)
    },
    async getCustomerOrderDetailsByProduct(context, data) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsByProduct(data)
        await loadCustomerOrderDetails(response, context)
        return response
    },
    async getCustomerOrderDetailsByProductPaged(context, pagination) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsByProductPaged(pagination)
        await loadCustomerOrderDetails(response, context)
    },
    async getCustomerOrderDetailsByCustomer(context, data) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsByCustomer(data)
        await loadCustomerOrderDetails(response, context)
    },
    async getCustomerOrderDetailsByCustomerPaged(context, pagination) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsByCustomerPaged(pagination)
        await loadCustomerOrderDetails(response, context)
    },
    async getCustomerOrderDetailsByOrderNumber(context, data) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsByOrderNumber(data)
        await loadCustomerOrderDetails(response, context)
    },
    async getCustomerOrderDetailsByOrderNumberPaged(context, pagination) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsByOrderNumberPaged(pagination)
        await loadCustomerOrderDetails(response, context)
    },
    async getCustomerOrderDetailsAuthorizedForDelivery(context) {
        const response = await customerOrdersEndpoints.getCustomerOrderDetailsAuthorizedForDelivery()
        if (response.status === 200) {
            context.commit('loadCustomerOrderDetailsAuthorizedForDelivery', response.data)
        }
    },

    async createOrEditCustomerOrder(context, data) {
        return await customerOrdersEndpoints.createOrEditCustomerOrder(data)
    },
    async deleteCustomerOrder(context, order) {
        let index = context.state.customerOrders.indexOf(order)
        const response = await customerOrdersEndpoints.deleteCustomerOrder(order.id)
        if (response.status === 200) context.commit('removeCustomerOrder', index)
    },

    async deleteOrderDetail(context, data) {
        return await customerOrdersEndpoints.deleteOrderDetail(data)
    },

    async authorizeDelivery(context, data) {
        const response = await customerOrdersEndpoints.authorizeDelivery(data.detail)
        if (response.status === 201) context.commit('uploadCustomerOrderDetail', data)
        return response
    },
    async deleteAuthorizedDelivery(context, id) {
        await customerOrdersEndpoints.deleteAuthorizedDelivery(id)
    },
}

async function loadCustomerOrderDetails(response, context) {
    if (response.status === 200) {
        context.commit('loadCustomerOrderDetails', response.data)
    }
}