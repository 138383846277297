export default {
    getStockConcepts(state) {
      return state.stockConcepts
    },
    getDeposits(state) {
      return state.deposits
    },
    getPurchaseOrdersDetailPendingEntry(state) {
      return state.purchaseOrdersDetailPendingEntry
    },
    getStockMovementByOrigin(state) {
      return state.stockMovementByOrigin
    },
    getStockMovementByOriginFiltered(state) {
      return searchCode => {
        return state.stockMovementByOrigin.filter(movement => {
          return movement.product.code.toLowerCase().includes(searchCode.toLowerCase())
        })
      }
    }
}