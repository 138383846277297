import axios from "axios";
import {host} from "@/store/config";

export default {
  async getSubfamilies() {
    return await axios.get(host + '/sub_families')
  },
  async createOrEditSubFamily(data) {
    return await axios.put(host + '/sub_family', data)
  },
  async deleteSubfamily(uuid) {
    return await axios.delete(host + '/sub_family', { data: {
      id: uuid,
    }})
  }
}